import "../../i18n";
import { useTranslation } from "react-i18next";
import "./CareersPage.scss"; // import CSS file
import SingleLayerIntroSection from "../../Components/Text/ParallaxTitle/Simple/SimpleParallaxTitle";
import Map from '../../Components/Maps/map';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import "../../i18n";
import { Team } from "../../Data/Constants";
import { CareersPageData } from "../../Data/Translation/CareersPage/Data_CareersPage";




export default function Careers(){
    const { t, i18n } = useTranslation();
    const currentPageData = i18n.language === 'fr' ? CareersPageData.CareersPageData_fr : CareersPageData.CareersPageData_en;

    return (
        <div>
            <SingleLayerIntroSection title={t("careers.title.text")} backgroundImage={Team.picture} subtitle="" textBackground={{enabled: true, color: "red", rounded: true, opacity: 0.5}}/>
            <div className="page-section">
                <div className="text-container-lg justify-text">
                    <h1>{t("careers.description.title")}</h1>
                    {
                        currentPageData.description.text.map((desc, index) => (
                            <div key={index}>{desc}</div>
                        ))
                    }
                </div>
            </div>
            <div className='map-section-container'>
                <div className='map-section-info'>
                    <div className='map-section-text'>
                        <h1>{t("location.title")}</h1>
                        <h5>{t("location.address")}</h5>
                        <div className="contact-text">
                            <div>
                                <span><FaPhone/></span> (514) 686-6868
                            </div>
                            <div>
                            <span><FaEnvelope/> </span><a href="mailto:info@simdev.ca">info@simdev.ca</a>
                            </div>
                        </div>
                </div>
                </div>
                <div className='map-section-content'>
                    <Map/>
                </div>
          </div>
        </div>
        
    )
}
