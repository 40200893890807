import image1 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-01.jpeg'
import image2 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-04.jpeg'
import image3 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-05.jpeg'
import image4 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-06.jpeg'
import image5 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-07.jpeg'
import image6 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-08.jpeg'
import image7 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-09.jpeg'
import image8 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-10.jpeg'
import image9 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-11.jpeg'
import image10 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-12.jpeg'
import image11 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-13.jpeg'
import image12 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-14.jpeg'
import image13 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-14A.jpeg'
import image14 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-14B.jpeg'
import image15 from '../../../Assets/images/LeVIVO/LeVIVO_Sherbrooke/SH-14C.jpeg'


/* IMAGES LEVIVO SHERBROOKE*/

export const slidesLVSH = [
    {
        url: image1
    },
    {
        url: image2
    },
    {
        url: image3
    },
    {
        url: image4
    },
    {
        url: image5
    },
    {
        url: image6
    },
    {
        url: image7
    },
    {
        url: image8
    },
    {
        url: image9
    },
    {
        url: image10
    },
    {
        url: image11
    },
    {
        url: image12
    },
    {
        url: image13
    },
    {
        url: image14
    },
    {
        url: image15
    },
]

export default slidesLVSH;