export const featuresLVLP = {
    fr: [
        "34 condos locatifs",
        "2 étages préfabriqués en bois et béton",
        "1 étage de stationnements sous-terrains",
        "11 mois d'éxécution",
        "Mandat forfaitaire",
    ],
    en: [
        "34 rental condos",
        "2 stories made of prefabricated wood and concrete",
        "1 underground parking level",
        "11 months of execution",
        "Lump-sum contract",
    ]
}
    