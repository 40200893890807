import { CompanyValues } from "./Values"

export const HistoryPageData = {
    history_en: {
        intro: {
            "title": "About SIMDEV"
        },
        content: {
            history: {
                "title": "Our history",
                "subtitle": "2009 - Today",
                "text": "Founded by two brothers driven by a shared passion for construction and development, SIMDEV was established on October 22, 2009. As third-generation entrepreneurs, their journey has been shaped by an early immersion in the industrial and pipeline sectors. Today, they are pushing boundaries by investing in the real estate field. Their commitment to creation and innovation is unwavering, thus exemplifying the epitome of the new generation of entrepreneurs they represent."
            },
            pillars: {
                "title": "4 pillars",
                "subtitle": "",
                "text": "At Simdev, we place emphasis on the four pillars of construction: safety, quality, schedule, and cost. Our management team relies on methods and technological tools to deliver a product that adheres to the highest industry standards."
            },
            mission: {
                "title": "Our mission",
                "subtitle": "",
                "text": "At the core of our priorities is the development and maintenance of long-term relationships with all our business partners. We sincerely believe that it is through developing quality relationships with our clients and subcontractors based on collaboration and authenticity that we succeed."
            },
            values: {
                "title": "Our company values",
                company_values: CompanyValues.values_en
            },
            awards: {
                "title": "Awards & Recognitions"
            }
        }
    },
    history_fr: {
        intro: {
            "title": "À propos de SIMDEV"
        },
        content: {
            history: {
                "title": "Notre histoire",
                "subtitle": "2009 - Aujourd-hui",
                "text": "Fondée par deux frères animés par une passion commune pour la construction et le développement, SIMDEV a vu le jour le 22 octobre 2009. En tant qu'entrepreneurs de la 3ème génération, leur parcours a été façonné par une immersion précoce dans le secteur industriel et des pipelines. Aujourd'hui, ils repoussent les frontières en investissant dans le domaine de l'immobilier. Leur engagement envers la création et l'innovation est inébranlable, illustrant ainsi la quintessence de la nouvelle génération d'entrepreneurs qu'ils représentent."
            },
            pillars: {
                "title": "4 pilliers",
                "subtitle": "",
                "text": "Chez Simdev, nous mettons l'emphase sur les 4 pilliers de la construction qui sont la sécurité, la qualité, l'échéancier et les coûts. Notre equipe de gestion s'appuient sur des méthodes et des outils technologiques afin de livrer un produit qui respecte les plus hauts standards de l'industrie."
            },
            mission: {
                "title": "La mission",
                "subtitle": "",
                "text": "Au coeur de nos priorités, développer et maintenir des relations à long terme avec tous nos partenaires d'affaires. Nous croyons sincèrement que c'est en développant des relations de qualité avec nos clients et nos sous-traitants basés sur la collaboration et l'authenticité que nous réussissons"
            },
            values: {
                "title": "Les valeurs de l'entreprise",
                company_values: CompanyValues.values_fr
            },
            awards: {
                "title": "Prix & Reconnaissances"
            }
        }
    }
}