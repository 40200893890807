import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ContactData } from "./Data/Translation/Data_ContactPage";
import { NavbarData } from "./Data/Translation/Data_Navbar";
import { HomeData } from "./Data/Translation/Data_HomePage";
import { FooterData } from "./Data/Translation/Data_Footer";
import { LocationData } from "./Data/Translation/Data_LocationPage";
import { ConstructionPageData } from "./Data/Translation/ConstructionPage/Data_ConstructionPage";
import { ManagementPageData } from "./Data/Translation/Data_ManagementPage";
import { HistoryPageData } from "./Data/Translation/HistoryPage/Data_HistoryPage";
import { ProjectsPageData } from "./Data/Translation/Data_ProjectsPage";
import { FiltersData } from "./Data/Translation/Data_Filters";
import { CardViewData } from "./Data/Translation/Data_CardView";
import { CareersPageData } from "./Data/Translation/CareersPage/Data_CareersPage";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
            navbar: NavbarData.navbar_en,
            home: HomeData.home_en,
            history: HistoryPageData.history_en,
            construction: ConstructionPageData.constructionPage_en,
            management: ManagementPageData.ManagementPage_en,
            projects: ProjectsPageData.ProjectsPage_en,
            location: LocationData.location_en,
            careers: CareersPageData.CareersPageData_en,
            contact: ContactData.contact_en,
            footer: FooterData.footer_en,
            filters: FiltersData.filters_en,
            card: CardViewData.CardViewData_en
        },
      },
      fr: {
        translation: {
            navbar: NavbarData.navbar_fr,
            home: HomeData.home_fr,
            history: HistoryPageData.history_fr,
            construction: ConstructionPageData.constructionPage_fr,
            management: ManagementPageData.ManagementPage_fr,
            projects: ProjectsPageData.ProjectsPage_fr,
            location: LocationData.location_fr,
            careers: CareersPageData.CareersPageData_fr,
            contact: ContactData.contact_fr,
            footer: FooterData.footer_fr,
            filters: FiltersData.filters_fr,
            card: CardViewData.CardViewData_fr
        }
      }
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "fr",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;

