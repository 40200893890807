export const featuresL144 = {
    features_fr: [
        "Siège social : 2 étages en structure de béton et de bois lamelé collé apparant",
        "Centre de formation : 2 étages en structure d'acier et béton",
        "18 mois d'éxécution en parallèle",
        "Mandat de gestion et construction en temps et matériel",
        "Projet LEED platine V4",
    ],
    en: [
        "Headquarters: 2 floors in concrete and exposed glued laminated timber structure",
        "Training center: 2 floors in steel and concrete structure",
        "18 months of parallel execution",
        "Time and material management and construction mandate",
        "LEED Platinum V4 project",
    ]
};

export default featuresL144;
    