export const textARCAND = {
    fr: [
        "En 2022, SIMDEV s'est lancée dans un ambitieux projet de rénovation pour redéfinir son siège social. Ce projet, achevé en 2023, a métamorphosé un immeuble industriel en un espace de bureau contemporain et innovant s'étalant sur deux étages.",
        "L'objectif premier était de créer un environnement de travail inspirant et fonctionnel, favorisant la collaboration et le bien-être des employés. La refonte complète a permis de repenser chaque détail de l'espace, offrant des installations modernes et une conception ergonomique.",
        "Une aire de travail ouverte a été créée pour encourager l'interaction et la créativité entre les équipes. Les bureaux et les espaces flexibles favorisent une ambiance collaborative propice à l'innovation.",
        "La cuisine, véritable cœur social de l'entreprise, a été entièrement repensée et équipée pour offrir un espace de restauration moderne et convivial. Un lounge a été aménagé, créant ainsi un espace dédié à la détente et aux échanges informels, favorisant les synergies et renforçant la cohésion au sein de l'équipe.",
        "Cette transformation complète du siège social de SIMDEV incarne notre engagement envers l'innovation, le confort et l'épanouissement professionnel de nos collaborateurs. Nous sommes fiers de fournir un environnement de travail à la pointe de la modernité, favorisant la productivité et l'épanouissement personnel de chacun",
    ],
    en: [
        "In 2022, SIMDEV embarked on an ambitious renovation project to redefine its headquarters. This project, completed in 2023, transformed an industrial building into a contemporary and innovative office space spanning two floors.",
        "The primary goal was to create an inspiring and functional work environment, promoting collaboration and the well-being of employees. The complete overhaul allowed for rethinking every detail of the space, providing modern facilities and an ergonomic design.",
        "An open work area was created to encourage interaction and creativity among teams. Desks and flexible spaces promote a collaborative atmosphere conducive to innovation.",
        "The kitchen, the true social heart of the company, was completely redesigned and equipped to offer a modern and friendly dining area. A lounge was set up, thus creating a space dedicated to relaxation and informal exchanges, fostering synergies and strengthening cohesion within the team.",
        "This complete transformation of the SIMDEV headquarters embodies our commitment to innovation, comfort, and professional development of our collaborators. We are proud to provide a cutting-edge work environment that promotes productivity and personal fulfillment for everyone."
    ]
}