import React, { useEffect, useRef } from 'react';
import './SimpleParallaxTitle.scss'; // Import the CSS file for styling
import { motion, useInView } from 'framer-motion';
import { ParallaxBanner } from "react-scroll-parallax";

interface IntroSectionProps {
    title: string;
    backgroundImage: string;
    animationEnabled?: boolean;
    subtitle?: string;
    textBackground?: {
      enabled: boolean;
      color: string;
      rounded: boolean;
      opacity: number;
    };
    backgroundOpacity?: number;
}

const SingleLayerIntroSection: React.FC<IntroSectionProps> = ({
    title,
    backgroundImage,
    // ... other props
}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    // Framer Motion animation variants
    const variants = {
        visible: { opacity: 1, x: 0, transition: { duration: 0.9, ease: "easeOut" } },
        hidden: { opacity: 0, x: '-200px' },
    };

    return (
        <motion.div className='full' ref={ref} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 2 }}>
            <ParallaxBanner
                layers={[
                    { image: backgroundImage, speed: -15 },
                ]}
                className="full"
            >
                <div className="single-section-content">
                    <span
                        style={{
                          transform: isInView ? "none" : "translateX(-200px)",
                          opacity: isInView ? 1 : 0,
                          transition: "all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 2s"
                        }}
                    >
                        <h1>{title}</h1>
                    </span>
                </div>
            </ParallaxBanner>
        </motion.div>
    );
}

export default SingleLayerIntroSection;
