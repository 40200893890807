import React, { useState, useEffect } from 'react';
import './LoadingButton.scss';
import { FaCheck, FaTimes  } from 'react-icons/fa';

interface LoadingButtonProps {
  onClick: () => void;
  isSubmitting: boolean;
  text: string;
  error?: boolean;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({ onClick, isSubmitting, text, error = false }) => {
  const [buttonState, setButtonState] = useState<'default' | 'onclic' | 'validate'>('default');

  useEffect(() => {
    if (buttonState === 'onclic') {
      setTimeout(() => {
        setButtonState('validate');
      }, 2250);
    }

    if (buttonState === 'validate') {
      setTimeout(() => {
        setButtonState('default');
      }, 1250);
    }
  }, [buttonState]);

  const handleClick = () => {
    if (!isSubmitting) {
      setButtonState('onclic');
      onClick();
    }
  };

  return (
    <div className="loading-button-container">
      <button
        className={`loading-button ${buttonState}`}
        id='loading-button'
        onClick={handleClick}
        disabled={isSubmitting}
      >
        {buttonState === 'default' && (isSubmitting ? 'SUBMITTING...' : text)}
        {buttonState === 'validate' && (error ? <FaTimes/>: <FaCheck/> )}
      </button>
    </div>
  );
};

export default LoadingButton;
