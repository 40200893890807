export const featuresLVGP = {
    fr: [
        "93 condos locatifs",
        "4 étages préfabriqués en acier et béton",
        "1 étage de stationnements sous-terrains",
        "14 mois d'éxécution",
        "Mandat forfaitaire"
    ],
    en: [
        "93 rental condos",
        "4 stories made of prefabricated steel and concrete",
        "1 underground parking level",
        "14 months of execution",
        "Lump-sum contract",
    ]

}