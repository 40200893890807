import slidesL144 from './slides_L44';
import { featuresL144 } from './features_L144';
import { ProjectType } from '../../Constants';
import { textL144 } from './text_Local144';

export const L144data = {
    L144data_fr: {
        id: 7,
        title: 'Local 144',
        description: textL144.fr,
        yearBuilt: 2019,
        location: "Montréal",
        website: "https://www.local144.ca/",
        category: ProjectType.Commercial,
        images: slidesL144,
        features: featuresL144.features_fr
    },
    L144data_en: {
        id: 7,
        title: 'Local 144',
        description: textL144.en,
        yearBuilt: 2019,
        location: "Montreal",
        website: "https://www.local144.ca/",
        category: ProjectType.Commercial,
        images: slidesL144,
        features: featuresL144.en
    }
    
}