import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import './Footer.scss'; // Assuming you have CSS file for your styles
import { Link } from "react-router-dom";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { links } from '../../Data/Constants';

interface Link {
    name: string;
    url: string;
}

const CONTACT_LINKS: Link[] = [
    { name: 'footer.contact.contact-link', url: '/nousrejoindre' },
    { name: 'footer.contact.privacy-link', url: '/politique-confidentialite' },
    { name: 'footer.contact.terms-link', url: '/avis-legal' },
];

const EXPLORE_LINKS: Link[] = [
    { name: 'footer.explore.about-link', url: '/histoire' },
    { name: 'footer.explore.careers-link', url: '/emplois' },
    //{ name: 'footer.explore.news-link', url: '/nouvelles' },
];

const currentYear = new Date().getFullYear().toString()

const Footer: React.FC = () => {
    const { t } = useTranslation();
    
    return (
        <div>
            <div className='bg-custom-dark margin-auto'>
                <div className="footer">
                    <div className="footer__section">
                        <div className="footer__container">
                            <h5>{t("footer.contact.title")}</h5>
                            {CONTACT_LINKS.map((link, index) => (
                                <Link key={index} to={link.url} className="nav-link footer-link-custom-color">
                                    {t(link.name)}
                                </Link>
                            ))}
                        </div>
                    </div>

                    <div className="footer__section">
                        <div className="footer__container">
                            <h5>{t("footer.explore.title")}</h5>
                            {EXPLORE_LINKS.map((link, index) => (
                                <Link key={index} to={link.url} className="nav-link footer-link-custom-color">
                                    {t(link.name)}
                                </Link>
                            ))}
                        </div>
                    </div>

                    <div className="footer__section">
                        <div className="footer__container">
                            <h5>{t("footer.media.title")}</h5>
                            <div className="footer-socials">
                                <a href={links.linkedin}>
                                    <FaLinkedinIn />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='custom-hr'></div>
                    <div className="footer__copyright">
                        {t("footer.copyright.name")} {currentYear}. {t("footer.copyright.copyright")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;

