export const ConstructionForces = {
    forces_fr: [
        "Bonne communication",
        "Gestion globale du projet",
        "Sécurité",
        "Qualité",
        "Coût",
        "Délais",
        "Portée",
        "Polyvalence",
        "Intégration des sous-traitants",
        "Priorités de nos clients",
    ],
    forces_en: [
        "Good communication",
        "Global project management",
        "Safety",
        "Quality",
        "Cost",
        "Timelines/Deadlines",
        "Scope",
        "Versatility",
        "Integration of subcontractors within our team",
        "Focus on our clients' priorities"
    ]
}