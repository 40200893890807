import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
//import 'bootstrap/dist/css/bootstrap.min.css';

type MyButtonProps = {
    OnClick: (open: boolean) => void;
    text: string;
    arrowAnimation?: boolean;
  }

const MyButton = ({ OnClick, text, arrowAnimation=false }: MyButtonProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('bootstrap/dist/css/bootstrap.min.css');
    }
  }, []);

  const handleClick = () => {
    setIsClicked(!isClicked);
    OnClick(!isClicked);
  }

  const variants = {
    hover: { backgroundColor: '#36a9e0', y: [-5, 5, -5, 5, 0], transition: { duration: 0.5 }, color: 'white' },
    normal: { backgroundColor: '#101A2C', y: 0, color:'white'},
  };

  return (
    <motion.button
      className="btn"
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      variants={variants}
      animate={hovered ? 'hover' : 'normal'}
    >
      {text} {arrowAnimation ? isClicked ? <FiArrowDown /> : <FiArrowUp />  : <></>}
    </motion.button>
  );
}

export default MyButton;
