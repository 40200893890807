import { ConstructionForces } from "./Forces";
import { ConceptionServices, ManagementServices, PreDevServices, PostConstructionServices } from "./Services";

export const ConstructionPageData = {
    constructionPage_fr: {
        title: {
            "title": "Construction",
            "subtitle": "",
        },
        phases: {
            pre_dev: {
                "title":"Pré-développement",
                "text": "Simdev offre des services de pré-développement afin de préparer le terrain à la construction",
                services: PreDevServices.fr
            },
            conception: {
                "title": "Conception",
                "text": "La phase conception est une étape importante dans la vie d'un projet et chaque décision a des répercussions lors de la construction. Accumulant plusieurs années d'expérience en la matière, simdev offre un soutient à ses clients afin d'optimiser le design, faciliter la construction et ultimement réduire les coûts et les échéanciers. Notre support inclus :",
                services: ConceptionServices.fr

            },
            management: {
                "title": "Gestion et contrôle de projet",
                "text": "SIMDEV est un leader dans l’industrie et s’engage à fournir à ses clients l’expertise et les outils nécessaires pour garantir et soutenir le succès organisationnel et la réussite des projets. Nous mettons en œuvre les meilleures méthodes et pratiques afin de nous assurer que chaque projet est réalisé et géré selon des standards élevés, tout en maintenant une communication ouverte et transparente avec nos clients.",
                services: ManagementServices.fr

            },
            construction: {
                "title": "Construction",
                "text": "SIMDEV partage le même objectif que ses clients, soit la réussite du projet. Nous adaptons les méthodes et les stratégies d’exécution aux besoins de chaque client.",
                services: ConstructionForces.forces_fr
            },
            post: {
                "title": "Post-construction",
                "text": "Notre engagement envers l'excellence ne s'arrête pas à la fin de la phase de construction. Chez SIMDEV, nous comprenons l'importance de maintenir et d'améliorer votre investissement au fil du temps. Nos services post-construction sont conçus pour assurer la durabilité et à la longévité de chaque projet bien au-delà de la construction initiale",
                services: PostConstructionServices.fr
            }
            
        }
    },
    constructionPage_en: {
            title: {
                "title": "General contractor",
                "subtitle": "",
            },
            phases: {
                pre_dev: {
                    "title": "Pre-Development",
                    "text": "Simdev offers pre-development services to prepare the site for construction.",
                    services: PreDevServices.en
                },
                conception: {
                    "title": "Conception",
                    "text": "The conception phase is a critical stage in a project's life, and every decision has implications during construction. With years of experience in the field, Simdev provides support to its clients to optimize the design, facilitate construction, and ultimately reduce costs and timelines. Our support includes:",
                    services: ConceptionServices.en
                },
                management: {
                    "title": "Project Management and Control",
                    "text": "SIMDEV is a leader in the industry and is committed to providing its clients with the expertise and tools necessary to ensure organizational success and project success. We implement the best methods and practices to ensure that each project is carried out and managed to high standards while maintaining open and transparent communication with our clients.",
                    services: ManagementServices.en
                },
                construction: {
                    "title": "Construction",
                    "text": "SIMDEV shares the same goal as its clients: project success. We adapt execution methods and strategies to the needs of each client.",
                    services: ConstructionForces.forces_en
                },
                post: {
                    "title": "Post-construction",
                    "text": "Our commitment to excellence does not stop at the end of the construction phase. At SIMDEV, we understand the importance of maintaining and enhancing your investment over time. Our post-construction services are designed to ensure the durability and longevity of each project well beyond the initial construction.",
                    services: PostConstructionServices.en
                }
                
            },
        },
  };
  
