export const featuresLVLO1 = {
    fr: [
        "124 condos locatifs",
        "4 étages préfabriqués en acier et béton",
        "1 étage de stationnements sous-terrains",
        "13 mois d'éxécution",
        "Mandat forfaitaire"
    ],
    en: [
        "124 rental condos",
        "4 stories made of prefabricated steel and concrete",
        "1 underground parking level",
        "13 months of execution",
        "Lump-sum contract",
    ]
}