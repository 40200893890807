export const FiltersData = {
    filters_en: {
        button: {
            "text": "Filters"
          },
          filters: {
            sortBy: {
              "title": "Sort by:",
              options: {
                "recent": "Most recent",
                "oldest": "Oldest",
              }
            },
            cities: {
                "title": "City:",
                options: {
                    "all": "All cities"
                }
            }
        },
    },
    filters_fr: {
      button: {
        "text": "Filtres"
      },
      filters: {
        sortBy: {
          "title": "Trier par:",
          options: {
            "recent": "Plus récent",
            "oldest": "Plus ancien",
          }
        },
        cities: {
            "title": "Ville:",
            options: {
                "all": "Toutes les villes"
            }
        }
      },
    },
  };
  