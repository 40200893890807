import slidesLVGP from './slides_LVGP';
import { featuresLVGP } from './features_LVGP';
import { ProjectType } from '../../Constants';
import { textLVGP } from './text_LVGP';

export const LVGPdata = {

    LVGPdata_fr:{
        id: 2,
        title: 'LeVIVO GreenField Park',
        description: textLVGP.fr,
        yearBuilt: 2020,
        location: "Greenfield Park",
        website: "https://levivo.ca/en/rentals-condos/levivo-greenfield-park/",
        category: ProjectType.RealEstate,
        images: slidesLVGP,
        features: featuresLVGP.fr
    },
    LGVPdata_en:{
        id: 2,
        title: 'LeVIVO GreenField Park',
        description: textLVGP.en,
        yearBuilt: 2020,
        location: "Greenfield Park",
        website: "https://levivo.ca/en/rentals-condos/levivo-greenfield-park/",
        category: ProjectType.RealEstate,
        images: slidesLVGP,
        features: featuresLVGP.en
    }
}