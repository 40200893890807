import { useState, useEffect } from 'react';
import { View } from '../../Data/Constants';

const BREAKPOINTS = {
  MOBILE: 768,
  TABLET_MD: 1024,
  TABLET_LG: 1280,
};

/*
  This script detect the current resolution (View) of the user and return a custom View enum
*/
const useDetectView = () => {
  const [view, setView] = useState<View>(View.Desktop); // default to Desktop

  function getScreenSize() {
    if (typeof window !== 'undefined') {
      const width = window.innerWidth;
      if (width <= BREAKPOINTS.MOBILE) return View.Mobile;
      if (width <= BREAKPOINTS.TABLET_MD) return View.Tablet;
      if (width <= BREAKPOINTS.TABLET_LG) return View.LargeTablet;
    }
    return View.Desktop; // Return a default value if `window` is undefined
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const checkSize = () => setView(getScreenSize());
      window.addEventListener('resize', checkSize);
      checkSize(); // Initialize the view on mount
      return () => window.removeEventListener('resize', checkSize);
    }
  }, []);

  return view;
};

export default useDetectView;