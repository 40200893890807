export const LocationData = {
    location_en: {
        "title": "Our offices",
        "address": "2425 Rue Arcand, Montreal, QC, Canada",
    },
    location_fr: {
        "title": "Nos bureaux",
        "address": "2425 Rue Arcand, Montreal, QC, Canada",
    },
  };
  