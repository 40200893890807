import { useMemo } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';

export default function Map(){
    const apiValue: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiValue
    })

    if (!isLoaded) return <div>Loading...</div>
    
    return (
    <div>
        <GMap/>
    </div>
    );

    function GMap(){
        const center = useMemo(() => ({lat: 45.58166149742457, lng: -73.52974498746171}), [])

        return (
            <GoogleMap zoom={17} center={center} mapContainerClassName="map-container">
                <MarkerF position={center}></MarkerF>
            </GoogleMap>
        )
    }
}

