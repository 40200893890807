import React, { useState, useCallback } from 'react';
import './Card.scss';
import { FaChevronRight } from "react-icons/fa";
import DetailedCardView from './CardDetailedView';
import useDetectView from '../Hook/useDetectView';
import { View, ProjectType } from '../../Data/Constants';
import { useTranslation } from 'react-i18next';
import { useSpring } from '@react-spring/web';

interface CardProps {
    id: number;
    title: string;
    description: string[];
    yearBuilt: number;
    location: string;
    website: string;
    category: ProjectType;
    images: { url: string }[];
    features: string[];
}

const getProjectTypeTranslationKey = (category: ProjectType) => {
    return category === ProjectType.Commercial 
        ? "projects.type.commercial" 
        : "projects.type.realEstate";
};

export const Card: React.FC<CardProps> = ({ 
    id,
    title, 
    description, 
    yearBuilt, 
    location,
    website, 
    category, 
    images, 
    features 
}) => {
    const { t } = useTranslation();
    const [isClicked, setIsClicked] = useState(false);
    const currentView = useDetectView();

    const animation = useSpring({
        transform: isClicked ? "translateY(0%)" : "translateY(100%)",
    });

    const handleClick = useCallback(() => {
        setIsClicked(prev => !prev);
    }, []);

    const handleImageClick = useCallback(() => {
        if (currentView === View.Tablet || currentView === View.Mobile) {
            handleClick();
        }
    }, [currentView, handleClick]);

    const projectType = t(getProjectTypeTranslationKey(category));

    return (
        <div className="project-card">
            <div onClick={handleImageClick}>
                <img className="project-card-image" src={images[0]?.url} alt={`Image of ${title}`} loading="lazy" />
                <div className="project-card-content">
                    <h2 className="project-card-title">{title}</h2>
                    <h3>{yearBuilt}</h3>
                    <p className="project-card-subtitle">{location}</p>
                    <button className="learn-more-button" onClick={handleClick}>
                        <span>{t("card.button.text")}</span>
                        <span><FaChevronRight /></span>
                    </button>
                </div>
            </div>
            <div className='project-card-full-background' onClick={handleClick}></div>
                <DetailedCardView isOpen={isClicked} 
                style={animation}
                id={id} 
                title={title} 
                description={description} 
                images={images} 
                yearBuilt={yearBuilt} 
                category={projectType} 
                location={location}
                website={website} 
                features={features}
                onClose={handleClick} />
            </div>
    );
};

export default Card;
