export const PreDevServices = {
    fr: [
        "Changement de zonage",
        "Processus PPCMOI, CCU ET CE",
        "Évaluation préliminaire des risques du projet",
        "Évaluation de la constructibilité du projet et des rendements",
        "Démolition",
        "Décontamination et environnement",
        "Sécurisation du site"
    ],
    en: [
        "Zoning change/rezoning",
        "PPCMOI, CCU, and CE processes",
        "Preliminary project risk assessment",
        "Project constructability and yields assessment",
        "Demolition",
        "Decontamination and environment",
        "Site security/safety measures",
    ]
}

export const ConceptionServices = {
    fr: [
        "Ingénierie de la valeur (value engeneering) permettant d'optimiser coûts et échéanciers",
        "Évaluation des risques de projet",
        "Évaluation de la constructibilité du projet",
        "Développement des échéanciers et estimation des coûts",
        "Prévision des flux de trésoreries (Cashflow plan)",
        "Coordination de tous les intervenants impliqués dans la conception (Architecture, ingénierie de structure, ingénérie mécanique/électrique, ingénierie des matériaux, ingénierie civile, architecture de paysage)"
    ],
    en: [
        "Value engineering (value engineering) to optimize costs and schedules",
        "Project risk assessment",
        "Project constructability assessment",
        "Development of schedules and cost estimation",
        "Cash flow forecasting (Cashflow plan)",
        "Coordination of all stakeholders involved in the design (Architecture, structural engineering, mechanical/electrical engineering, materials engineering, civil engineering, landscape architecture)",

    ]
}

export const ManagementServices = {
    fr: [
        "Gestion de projet",
        "Gestion de construction",
        "Gestion de portefeuille",
        "Administration de contrats",
        "Assistance à la construction",
        "Revues de constructibilité",
        "Représentation du propriétaire",
    ],
    en: [
        "Project management",
        "Construction management",
        "Portfolio management",
        "Contract administration",
        "Construction assistance",
        "Constructibility reviews",
        "Owner representation",
    ]
}

export const ControlServices = {
    fr: [
        "Gestion des échéanciers et du progrès",
        "Contrôle des coûts",
        "Gestion des risques",
        "Gestion du changement",
        "Contrôle des documents",
        "Logiciels de gestion et contrôle de projet",
    ],
    en: [
        "Schedule and progress management",
        "Cost control",
        "Risk management",
        "Change management",
        "Document control",
        "Project management and control software",
    ]
}

export const PostConstructionServices = {
    fr: [
        "Inspection et Maintenance Régulières",
        "Réparations et Rénovations",
        "Suivi de Garantie",
        "Conseils en Gestion Immobilière",
    ],
    en: [
        "Regular Inspection and Maintenance",
        "Repairs and Renovations",
        "Warranty Follow-up",
        "Real Estate Management Advice",
    ]
}

