import slidesLVLP from './slides_LVLP';
import { featuresLVLP } from './features_LVLP';
import { ProjectType } from '../../Constants';
import { textLVLP } from './text_LVLP';

export const LVLPdata = {
    LVLPdata_fr: {
        id: 3,
        title: 'LeVIVO LaPrairie',
        description: textLVLP.fr,
        yearBuilt: 2019,
        location: "LaPrairie",
        website: "https://levivo.ca/en/rentals-condos/levivo-la-prairie/",
        category: ProjectType.RealEstate,
        images: slidesLVLP,
        features: featuresLVLP.fr
    },
    LVLPdata_en: {
        id: 3,
        title: 'LeVIVO LaPrairie',
        description: textLVLP.en,
        yearBuilt: 2019,
        location: "LaPrairie",
        website: "https://levivo.ca/en/rentals-condos/levivo-la-prairie/",
        category: ProjectType.RealEstate,
        images: slidesLVLP,
        features: featuresLVLP.en
    }
}