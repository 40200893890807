export const CardViewData = {
    CardViewData_en: {
        button: {
            "text": "Learn more"
        },
        detailed: {
            keypoint: {
                "location": "Location",
                "yearBuilt": "Year",
                "type": "Type"
            },
            button: {
                "text": "See project"
            },
            headers: {
                "description": "Description",
                'keypoints': "Key points"
            }
        }
    },
    CardViewData_fr:{
        button: {
            "text": "En savoir plus"
        },
        detailed: {
            keypoint: {
                "location": "Location",
                "yearBuilt": "Année",
                "type": "Type"
            },
            button: {
                "text": "Voir projet"
            },
            headers: {
                "description": "Description",
                'keypoints': "Points clés"
            }
        }
    }
}