import slidesLVLO1 from './slides_LVLO1';
import { featuresLVLO1 } from './features_LVLO1';
import { ProjectType } from '../../Constants';
import { textLVLO1 } from './text_LVLO1';

export const LVLO1data = {
    LVLO1data_fr: {
        id: 4,
        title: 'LeVIVO longueuil 1',
        description: textLVLO1.fr,
        yearBuilt: 2018,
        location: "Longueuil",
        website: "https://levivo.ca/en/rentals-condos/levivo-longueuil-1/",
        category: ProjectType.RealEstate,
        images: slidesLVLO1,
        features: featuresLVLO1.fr
    },
    LVLO1data_en: {
        id: 4,
        title: 'LeVIVO longueuil 1',
        description: textLVLO1.en,
        yearBuilt: 2018,
        location: "Longueuil",
        website: "https://levivo.ca/en/rentals-condos/levivo-longueuil-1/",
        category: ProjectType.RealEstate,
        images: slidesLVLO1,
        features: featuresLVLO1.en
    }
}

