export const ContactData = {
    contact_en: {
      title: {
        title: "Let's connect",
        subtitle: "Or contact us at",
      },
      form: {
        fields: {
          "first-name": "First Name",
          "last-name": "Last Name",
          email: "Email",
          message: "Message",
        },
        warning: {
          "partOne": "By clicking on \"Submit\", you are agreeing to the",
          "partTwo": "terms and conditions",
          "partThree": "and the",
          "partFour": "privacy policy",
          "partFive": "of SIMDEV"
        },
        button: "Submit",
      },
    },
    contact_fr: {
      title: {
        title: "Nous rejoindre",
        subtitle: "Ou nous contacter à",
      },
      form: {
        fields: {
          "first-name": "Prénom",
          "last-name": "Nom",
          email: "Courriel",
          message: "Message",
        },
        warning: {
          "partOne": "En cliquant le bouton \"Soumettre\", vous acceptez les",
          "partTwo": "termes et conditions",
          "partThree": "ainsi que la",
          "partFour": "politique de confidentialité",
          "partFive": "de SIMDEV"
        },
        button: "Soumettre",
      },
    },
  };
  