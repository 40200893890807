export const ProjectsPageData = {
    ProjectsPage_en: {
        "title": "Our projects",
        filters: {
            "Button": "Filters",
            sort: {
                "label": "Sort by",
                values: [
                    "Most recent"
                ]
            },
            cities: [
                "Longueuil",
                "LaPrairie",
                "Greenfield Park",
                "Sherbrooke"
            ]
        },
        buttons: {
            "goTo": "Learn more"
        },
        type: {
            "commercial": "Commercial",
            "realEstate": "Real Estate"
        }
    },
    ProjectsPage_fr: {
        "title": "Nos projets",
        filters: {
            "button": "Filtres",
            sort: {
                "label": "Trier par",
                values: [
                    "Plus recent"
                ]
            },
            cities: [
                "Longueuil",
                "LaPrairie",
                "Greenfield Park",
                "Sherbrooke"
            ]
        },
        buttons: {
            "goTo": "Learn more"
        },
        type: {
            "commercial": "Commercial",
            "realEstate": "Résidentiel"
        }
    },
  };
  