import { LVGPdata } from "../LeVIVO GreenfieldPark/Data_LVGP"; 
import { LVLPdata } from "../LeVIVO LaPrairie/Data_LVLP";
import { LVLO1data } from "../LeVIVO LG1/Data_LVLO1";
import { LVLO2data } from "../LeVIVO LG2/Data_LVLO2";
import { LVSHdata } from "../LeVIVO Sherbrooke/Data_LVSH";
import { L144data } from "../Local 144/Data_L144";
import { ARCANDdata } from "../Arcand/Data_Arcand";


export const projectsData = [
    LVGPdata.LGVPdata_en,
    LVLO1data.LVLO1data_en,
    LVLO2data.LVLO2data_en,
    LVSHdata.LVSHdata_en,
    LVLPdata.LVLPdata_en,
    L144data.L144data_en,
    ARCANDdata.ARCANDdata_en
  ];

export default projectsData;