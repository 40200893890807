export const CompanyValues = {
    values_fr: [
        {
            "value": "Innovation",
            "description": "L'innovation est au cœur de notre travail. Nous sommes en constante amélioration de la construction en mettant en œuvre de nouvelles idées et des solutions ingénieuses en réponse à la complexité des projets que nous réalisons."
        },
        {
            "value": "Authenticité",
            "description": "La sincérité et l'intégrité sont la base de notre fondation. Grâce à notre approche, nous réussissons à bâtir des relations durables."
        },
        {
            "value": "Engagement",
            "description": "La fiabilité et la collaboration sont profondément ancrées dans nos valeurs et définissent l'essence de notre vision."
        },
        {
            "value": "Excellence",
            "description": "La rigueur et la persévérance représentent la promesse d'un travail accompli selon les hauts standards de l'industrie, quel que soit le projet."
        },
    ],
    values_en: [
        {
            "value": "Innovation",
            "description": "Innovation is at the heart of our work. We are constantly improving construction by implementing new ideas and ingenious solutions in response to the complexity of the projects we undertake."
        },
        {
            "value": "Authenticity",
            "description": "Sincerity and integrity are the foundation of our business. Thanks to our approach, we manage to build long-lasting relationships."
        },
        {
            "value": "Commitment",
            "description": "Reliability and collaboration are deeply embedded in our values and define the essence of our vision."
        },
        {
            "value": "Excellence",
            "description": "Rigor and perseverance represent the promise of work completed according to the highest industry standards, regardless of the project."
        },
    ],
}

