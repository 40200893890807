
//LOGO IMPORTS
import logoDefault from '../Assets/images/Logo/SIMDEV_Logo_NoBackground.png'
import LogoMinimalist from '../Assets/images/Logo/SIMDEV_Logo-minimalist.jpg'
import LogoCircle from '../Assets/images/Logo/SIMDEV-white-circle.png'

//TEAM PHOTO IMPORTS
import fullTeam from '../Assets/images/Company/Team/Team.jpg'

export const Title = "SIMDEV"

export const Team = {
  ceo: {
    name: "Luc Simard"
  },
  coo: {
    name: "Redouane Fadadi"
  },
  cfo: {

  },
  picture: fullTeam,
  
}

export const Logo_Default = logoDefault
export const Logo_Minimalist = LogoMinimalist
export const Logo_Circle = LogoCircle

export enum View {
  Desktop = "desktop",
  Tablet = "tablet",
  LargeTablet = "largeTablet",
  Mobile = "mobile"
}

export enum ProjectType {
  Commercial,
  RealEstate
}

export const links = {
  facebook: " ",
  linkedin: "https://www.linkedin.com/company/construction-simdev-inc-",
  twitter: " ",
}

export enum ProjectLocation{
  
}

