export const featuresLVSH = {
    fr: [
        "97 condos locatifs",
        "4 étages préfabriqués en acier et béton",
        "1 étage de stationnements sous-terrains",
        "16 mois d'éxécution",
        "Mandat de gestion et de construction au prix coûtant plus les honoraires \"cost plus\"",
    ],
    en: [
        "97 rental condos",
        "4 stories made of prefabricated steel and concrete",
        "1 underground parking level",
        "16 months of execution",
        "Management and construction mandate at cost plus fees \"cost plus\"",
    ]
}