import React from 'react';
import { motion } from 'framer-motion';
import { Parallax } from 'react-scroll-parallax';
import './VideoTitle.scss';
import MainVideo from '../../../Assets/videos/ProjectsShowcase.MP4'

const videoSource = MainVideo;

interface VideoTitleProps {
  title: string;
  subtitle: string;
}

export const VideoTitle: React.FC<VideoTitleProps> = ({ title, subtitle }) => {
  return (
    <motion.div className="intro-section"  initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1, duration: 2 }}>
      <video className='videoTag' autoPlay loop muted disablePictureInPicture playsInline>
        <source src={videoSource} type='video/mp4' />
      </video>
      <Parallax className="title-container" speed={-10}>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2, duration: 2 }}
          className="subtitle"
        >
          {subtitle}
        </motion.h1>
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 3, duration: 2 }}
          className="title"
        >
          {title}
        </motion.h2>
      </Parallax>
    </motion.div>
  );
}

export default VideoTitle;

