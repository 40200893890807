export const FooterData = {
    footer_en: {
        contact: {
            "title": "Contact",
            "office-link": "Our Office",
            "contact-link": "Contact Us",
            "privacy-link": "Privacy policy",
            "terms-link": "Terms & Conditions"
        },
        explore : {
            "title": "Explore",
            "about-link": "About Us",
            "careers-link": "Careers",
            "news-link": "News",
            "awards-link": "Awards",
        },
        media: {
            "title": "Follow Us"
        },
        copyright: {
            "name": "Simdev",
            "copyright": "All rights reserved."
        }
    },
    footer_fr: {
        contact: {
            "title": "Contact",
            "office-link": "Nos bureaux",
            "contact-link": "Nous Contactez",
            "privacy-link": "Politique de confidentialité",
            "terms-link": "Termes & Conditions"
        },
        explore : {
            "title": "En savoir plus",
            "about-link": "À Propos",
            "careers-link": "Carrières",
            "news-link": "Actualités",
            "awards-link": "Prix & Accolades",
        },
        media: {
            "title": "Nous suivre"
        },
        copyright: {
            "name": "Simdev",
            "copyright": "Tout droits réservés."
        }
    },
  };
  