import React, { useEffect, useState } from 'react';
import { animated } from '@react-spring/web';
import { ImageSlider } from '../Carousel/Classic/ImageSlider';
import './CardDetailedView.scss'
import { BsXLg } from 'react-icons/bs';
import { Logo_Default, Logo_Circle } from '../../Data/Constants';
import "../../i18n";
import { useTranslation } from "react-i18next";
import { VAngledBackgroundTextSection } from '../Text/AngledBackgroundTextSection/AngledBackgroundTextSection';


interface DetailedCardViewProps {
    isOpen: boolean;
    style: any;
    id: number;
    title: string;
    description: string[];
    images: {url:string}[];
    features: string[];
    yearBuilt: number;
    location: string;
    website: string;
    category: string;
    
    onClose: () => void;
}

const currentYear = new Date().getFullYear().toString()

export const DetailedCardView: React.FC<DetailedCardViewProps> = ({ isOpen, style, title, description, images, features, yearBuilt, location, website, category, onClose }) => {
    const [scrollBar, setScrollBar] = useState('auto');
    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100%';
            setScrollBar('auto');
        } else {
            // this will hide the scrollbar immediately
            setScrollBar('hidden');
            // this will return the body overflow back to normal after 1.5 seconds
            setTimeout(() => {
                document.body.style.overflow = '';
                document.body.style.height = '';
            }, 300);
        }
    }, [isOpen]);
    
    return (
    <animated.div className="card-full" style={style}>
        <div className="close-button-container">
            <button className="close-button" onClick={onClose}>
                <BsXLg/>
            </button>
        </div>
        <div className="detailed-view-container">
            <div className="logo-container">
                <img src={Logo_Circle} className='company-logo'/>
            </div>
            <div className="project-header-container side-margins">
                <div className='project-title-container'>
                    <h1 className="project-title">{title}</h1>
                </div>
                <hr/>
                <div className="project-info-container">
                    <div className="project-info-item">
                        <p className="detail-title">{t("card.detailed.keypoint.location")}</p>
                        <p className="detail">{location}</p>
                    </div>
                    <div className="project-info-item">
                        <p className="detail-title">{t("card.detailed.keypoint.yearBuilt")}</p>
                        <p className="detail">{yearBuilt}</p>
                    </div>
                    <div className="project-info-item">
                        <p className="detail-title">{t("card.detailed.keypoint.type")}</p>
                        <p className="detail">{category}</p>
                    </div>
                </div>
                <hr/>
            </div>
            <div className='slider-parent'>
                <ImageSlider slides={images} autoplay={true} parentHeight='800' parentWidth='1000' />
            </div>
            {/* NEW CODE }
            <VAngledBackgroundTextSection 
                leftChildren={
                    <div className='justify-text'>
                        <h4>{t("card.detailed.headers.description")}</h4>
                            {
                                description.map((desc, index) => (
                                    <div key={index}>{desc}</div>
                                ))
                            }
                    </div>
                }
                rightChildren={
                    <div>
                        <h4>{t("card.detailed.headers.keypoints")}</h4>
                        <ul className="features-list">
                            {features.map(feature => <li className="features-list-item">{feature}</li>)}
                        </ul>
                    </div>
                }
                />
            */
            }
            {
            /* OLD CODE*/
            <div className='project-description-container side-margins justify-text'>
                <h3>{t("card.detailed.headers.description")}</h3>
                {
                    description.map((desc, index) => (
                        <p key={index}>{desc}</p>
                    ))
                }
                <hr></hr>
                <h4>{t("card.detailed.headers.keypoints")}</h4>
                <ul className="features-list">
                    {features.map(feature => <li className="features-list-item">{feature}</li>)}
                </ul>
            </div>
            }

            {
                title.toLowerCase().replace(/\s/g, "")!=="local144" ? 
                <div style={{marginBottom: '2rem'}}>
                    <a href={website}><button className="btn-submit">{t("card.detailed.button.text")}</button></a>
                </div>
                :
                <></>
            }
            <div>
                {t("footer.copyright.name")} {currentYear}. {t("footer.copyright.copyright")}
            </div>
        </div>
    </animated.div>
    );
};

export default DetailedCardView;

