import slidesARCAND from './slides_Arcand';
import { featuresARCAND } from './features_Arcand';
import { ProjectType } from '../../Constants';
import { textARCAND } from './text_Arcand';

export const ARCANDdata = {
    ARCANDdata_fr: {
        id: 1,
        title: 'Siège social de SIMDEV',
        description: textARCAND.fr,
        yearBuilt: 2023,
        location: "Montréal",
        website: "https://www.simdev.ca",
        category: ProjectType.Commercial,
        images: slidesARCAND,
        features: featuresARCAND.fr
    },
    ARCANDdata_en: {
        id: 1,
        title: 'SIMDEV Head Office',
        description: textARCAND.en,
        yearBuilt: 2023,
        location: "Montreal",
        website: "https://www.simdev.ca",
        category: ProjectType.Commercial,
        images: slidesARCAND,
        features: featuresARCAND.en
    }
    
}