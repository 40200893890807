export const ManagementPageData = {
    ManagementPage_fr: {
        intro: {
            "title": "Gestion immobilière",
            "text": "La gestion immobilière atteint une nouvelle dimension de l'excellence avec notre entreprise. En alliant construction et gestion de complexes d'appartements locatifs, nous maîtrisons l'intégralité de la chaîne immobilière, garantissant ainsi un service sans égal dans l'industrie.",
        },
        services: {
            rental: {
                "title":"Gestion de la location",
                "text": "La sélection des locataires et la gestion optimale de leur expérience locative sont au cœur de notre offre. L'équipe dédiée à cette mission assure un déroulement optimal durant tout le processus de la prospection, des visites de logements, de la sélection, de la vérification , de la signature des baux, des renouvellements et des résiliations.",
            },
            financing: {
                "title": "Services financiers et comptabilité",
                "text": "Offrant des services financiers et comptables complets, la tranquillité d'esprit est au rendez-vous pour tous les propriétaires. La collecte des loyers, le suivi des paiements, la gestion des dépôts de garantie, la préparation des déclarations fiscales sont des tâches complexes et sensibles gérées avec minutie et rigueur.",
            },
            marketing: {
                "title": "Marketing et publicité",
                "text": "Nous utilisons des stratégies de marketing ciblées pour promouvoir les immeubles, maximisant ainsi la visibilité auprès des locataires potentiels.",
            },
            maintenance: {
                "title": "Gestion de l'entretien des bâtiments locatifs",
                "text": "Assurer la pérennité et la valorisation du bien est une mission que nous prenons très à cœur. L'entretien des bâtiments locatifs est réalisé avec la plus grande attention, afin de garantir une satisfaction maximale des locataires et une rentabilité optimale pour les propriétaires",
            },
            optimization: {
                "title": "Optimisation Financière",
                "text": "Nous travaillons à maximiser vos rendements en développant des stratégies financières personnalisées. De la tarification des loyers à la réduction des coûts d'exploitation, nous visons à accroître la rentabilité de votre portefeuille immobilier."
            }
            
        }

    },
    ManagementPage_en: {
            intro: {
                "title": "Real estate management",
                "text": "Property management takes on a new dimension of excellence with our company. By combining the construction and management of rental apartment complexes, we master the entire real estate chain, thus guaranteeing an unparalleled service in the industry.",
            },
            services: {
                rental: {
                    "title": "Rental management",
                    "text": "The selection of tenants and the optimal management of their rental experience are at the heart of our offer. The team dedicated to this mission ensures an optimal process throughout the entire procedure of prospecting, housing visits, selection, verification, lease signing, renewals, and terminations.",
                },
                financing: {
                    "title": "Financial and accounting services",
                    "text": "By offering comprehensive financial and accounting services, peace of mind is guaranteed for all property owners. The collection of rents, the monitoring of payments, the management of security deposits, the preparation of tax returns are complex and sensitive tasks handled with meticulousness and rigor.",
                },
                marketing: {
                    "title": "Marketing and Advertising",
                    "text": "We use targeted marketing strategies to promote the buildings, thereby maximizing visibility among potential tenants.",
                },
                maintenance: {
                    "title": "Maintenance management of rental buildings",
                    "text": "Ensuring the longevity and valuation of the property is a mission that we take very seriously. The maintenance of rental buildings is carried out with the utmost care, to ensure maximum tenant satisfaction and optimal profitability for owners.",
                },
                optimization: {
                    "title": "Financial optimization",
                    "text": "We work to maximize your returns by developing personalized financial strategies. From rental pricing to reducing operating costs, we aim to increase the profitability of your real estate portfolio."
                }
            },
        },
  };
  
