import image1 from "../../../Assets/images/Arcand/images/Simdev-0.jpg";
import image2 from "../../../Assets/images/Arcand/images/Simdev-1.jpg";
import image3 from "../../../Assets/images/Arcand/images/Simdev-2.jpg";
import image4 from "../../../Assets/images/Arcand/images/Simdev-3.jpg";
import image5 from "../../../Assets/images/Arcand/images/Simdev-7.jpg";
import image6 from "../../../Assets/images/Arcand/images/Simdev-8.jpg";
import image7 from "../../../Assets/images/Arcand/images/Simdev-9.jpg";

/* IMAGES LEVIVO LAPRAIRIE*/
export const slidesARCAND = [
    {
        url: image1
    },
    {
        url: image2
    },
    {
        url: image3
    },
    {
        url: image4
    },
    {
        url: image5
    },
    {
        url: image6
    },
    {
        url: image7
    },
    
]

export default slidesARCAND;