import React, { useState } from 'react';
import Card from './Card';
import './CardList.scss';
import { AnimatePresence, motion } from 'framer-motion';
import projectsData_FR from "../../Data/Projects/FullData/projectsData_FR"
import projectsData_EN from "../../Data/Projects/FullData/projectsData_EN"
import "../../i18n";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/SimpleButton/Button';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function CardList() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState<string | null>('recent');
  const [locationFilter, setLocationFilter] = useState<string | null>(null);

  let cardsData = i18n.language === "fr" ? projectsData_FR : projectsData_EN;
  // Event handlers
  const handleSortDirectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortDirection(event.target.value);
  };

  const handleLocationFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLocationFilter(event.target.value);
  };

  const getSortedData = () => {
    console.log("LOCATION: " + locationFilter)
    let filteredData = cardsData.filter(card => 
      !locationFilter || card.location === locationFilter
    );
  
    if (sortDirection) {
      filteredData.sort((a, b) => {
        return sortDirection === 'recent' ? 
               Number(b.yearBuilt) - Number(a.yearBuilt) : 
               Number(a.yearBuilt) - Number(b.yearBuilt);
      });
    }
  
    return filteredData;
  };
  
  // Use the sorted data for rendering
  const sortedCardsData = getSortedData();

  return (
    <div>
      <div>
        <div className="line-button-container">
          <div className="line"></div>
          <div>
            <Button  OnClick={() => setOpen(o => !o)} text={t("filters.button.text")}/>
          </div>
          <div className="line"></div>
        </div>
        <div>
          <Collapse in={open}>
            <div className="hidden-container">
              <Row className="mb-2">
              <Form.Group as={Col} controlId="formGridSortDirection">
                <Form.Label>{t("filters.filters.sortBy.title")}</Form.Label>
                <Form.Select onChange={handleSortDirectionChange} defaultValue="recent">
                  <option value="recent">{t("filters.filters.sortBy.options.recent")}</option>
                  <option value="oldest">{t("filters.filters.sortBy.options.oldest")}</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridLocationFilter">
                <Form.Label>{t("filters.filters.cities.title")}</Form.Label>
                <Form.Select onChange={handleLocationFilterChange} defaultValue="all">
                  <option value="">{t("filters.filters.cities.options.all")}</option>
                  <option value="Longueuil">Longueuil</option>
                  <option value="LaPrairie">LaPrairie</option>
                  <option value="Greenfield Park">Greenfield Park</option>
                  <option value="Charlemagne">Charlemagne</option>
                  <option value="Sherbrooke">Sherbrooke</option>
                </Form.Select>
              </Form.Group>
              </Row>
            </div>
          </Collapse>
        </div>
      </div>
      <div className="card-grid">
        <AnimatePresence>
          {sortedCardsData.map((card, index) => (
            <motion.div
              key={card.id} // Preferably use a unique id rather than index
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
             <Card key={index}
                id={card.id}
                title={card.title} 
                description={card.description} 
                yearBuilt={card.yearBuilt} 
                location={card.location}
                website={card.website}
                category={card.category}
                images={card.images}
                features={card.features} />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default CardList;

