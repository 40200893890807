export const featuresARCAND = {
    fr: [
        "Structure en acier et béton",
        "Stationnements extérieurs",
        "12 mois d'exécution",
        "Mandat de gestion et de construction au prix coûtant plus les honoraires \"cost plus\"",
    ],
    en: [
        "Steel and concrete structure",
        "Outdoor parking",
        "12 months of execution",
        "Management and construction mandate at cost price plus 'cost plus' fees",
    ],
};
    
export default featuresARCAND;