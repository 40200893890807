import { useTranslation } from 'react-i18next';
import Privacy_en from '../../Components/Privacy/Privacy.en';
import Privacy_fr from '../../Components/Privacy/Privacy_fr';

export function Privacy() {
  const { i18n } = useTranslation();
  
      return (
        <div>
          {
            i18n.language === "fr" ? <Privacy_fr/> : <Privacy_en/>
          }
        </div>
    )
  }
  
  export default Privacy;