import { useTranslation } from 'react-i18next';
import Terms_en from '../../Components/Terms/Terms_en';
import Terms_fr from '../../Components/Terms/Terms_fr';

export default function Terms(){
    const { i18n } = useTranslation();

    return (
        <div>
           {
             i18n.language === "fr" ? <Terms_fr/> : <Terms_en/>
           }
        </div>
    )
}