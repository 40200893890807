import { useSpring, animated } from "@react-spring/web";
import './AnimatedNumber.scss'
import { useInView } from 'react-intersection-observer';

interface AnimatedNumberProps {
    num: number;
    delayInSecond?: number;
    durationInSecond?: number;
    numMass?: number;
    speed?: number;
    numTension?: number;
    numFriction?: number;
    circle?: boolean;
    text?: string;
    height?: number;
    width?: number;
    isDark?: boolean;
    fontSize?: number;
    isAnimated?:boolean;
    animationDelay?: number;
    side_text?: string;
    enableShadow?: boolean;
}

export const AnimatedNumber = ({
  num,
  delayInSecond = 0,
  numMass = 1,
  numTension = 20,
  numFriction = 10,
  circle = false,
  text = "",
  speed = 1,
  side_text = "",
  height = 100,
  width = 100,
  isDark = true,
  fontSize = 15,
  animationDelay = 0,
  enableShadow = false,
}: AnimatedNumberProps) => {

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    delay: animationDelay,
  });

  const { number } = useSpring({
    from: { number: 0},
    number: inView ? num : 0, // animate the number only if in view
    delay: inView ? delayInSecond * 1000 : 0, // apply the delay only if in view
    config: {
      mass: numMass, 
      tension: numTension * speed, 
      friction: numFriction
    }
  });

  const formatNumber = (num: number): string => {
    const numStr = num.toFixed(0);
    if (numStr.length === 5) {
        return `${numStr.substring(0, 2)} ${numStr.substring(2)}`;
    } else if (numStr.length === 6) {
        return `${numStr.substring(0, 3)} ${numStr.substring(3)}`;
    } else {
        return numStr;
    }
}

  return (
    <animated.div ref={ref} className={`CustomColumn right ${ circle ? 'circle' : '' } ${ enableShadow && circle ? 'shadow' : '' }`} style={{ 
      ...animationProps,
      backgroundColor: isDark ? '#101A2C': 'white',
      color: isDark ? 'white': '#101A2C',
      height: `${height}px`, 
      width: `${width}px`, 
      fontSize: `${fontSize}px`
    }}>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <animated.div style={{ ...animationProps }}>
            {number.to((n) => formatNumber(n))}
          </animated.div>
          {side_text && (
            <div style={{ fontSize: '2rem', marginLeft: '5px' }}>{side_text}</div>
          )}
        </div>
        <div>
          {text}
        </div>
      </div>
    </animated.div>
  )
}
