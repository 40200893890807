import './Terms.scss'

export default function Terms_fr(){
    return (
        <div>
            <div className='terms-container justify-text'>
                <h2><strong>Conditions Générales d'Utilisation</strong></h2>
                <p>Bienvenue sur simdev.ca.</p>

                <p>Ces termes et conditions définissent les règles et régulations pour l'utilisation du site Web de SIMDEV, situé à simdev.ca.</p>

                <p>En accédant à ce site web, nous supposons que vous acceptez ces termes et conditions. Ne continuez pas à utiliser simdev.ca si vous n'acceptez pas tous les termes et conditions énoncés sur cette page.</p>

                <p>La terminologie suivante s'applique à ces Termes et Conditions, Déclaration de Confidentialité et Avis de Non-Responsabilité et tous les Accords : "Client", "Vous" et "Votre" se réfèrent à vous, la personne se connectant sur ce site web et se conformant aux termes et conditions de la Société. "La Société", "Nous-même", "Nous", "Notre" et "Nous", se réfèrent à notre Société. "Partie", "Parties", ou "Nous", se réfèrent à la fois au Client et à nous-même. Tous les termes se réfèrent à l'offre, l'acceptation et la considération du paiement nécessaire pour entreprendre le processus de notre assistance au Client de la manière la plus appropriée dans le but exprès de répondre aux besoins du Client en ce qui concerne la fourniture des services déclarés de la Société, conformément à et sous réserve de, la loi en vigueur de ca. L'utilisation de la terminologie ci-dessus ou d'autres mots au singulier, pluriel, majuscules et/ou il/elle ou ils, sont pris comme interchangeables et donc comme se référant à la même chose.</p>

                <h3><strong>Cookies</strong></h3>

                <p>Nous utilisons des cookies. En accédant à simdev.ca, vous avez accepté d'utiliser des cookies en accord avec la Politique de Confidentialité de SIMDEV.</p>

                <p>La plupart des sites web interactifs utilisent des cookies pour nous permettre de récupérer les détails de l'utilisateur à chaque visite. Les cookies sont utilisés par notre site web pour activer la fonctionnalité de certaines zones afin de faciliter la visite des personnes sur notre site web. Certains de nos partenaires affiliés/publicitaires peuvent également utiliser des cookies.</p>

                <h3><strong>Licence</strong></h3>

                <p>Sauf indication contraire, SIMDEV et/ou ses concédants de licence possèdent les droits de propriété intellectuelle pour tout le matériel sur simdev.ca. Tous les droits de propriété intellectuelle sont réservés. Vous pouvez accéder à cela depuis simdev.ca pour votre usage personnel soumis aux restrictions définies dans ces termes et conditions.</p>

                <p>Vous ne devez pas :</p>
                <ul>
                    <li>Republier du matériel de simdev.ca</li>
                    <li>Vendre, louer ou sous-licencier du matériel de simdev.ca</li>
                    <li>Reproduire, dupliquer ou copier du matériel de simdev.ca</li>
                    <li>Redistribuer le contenu de simdev.ca</li>
                </ul>

                <h3><strong>Création de liens vers notre contenu</strong></h3>

                <p>Les organisations suivantes peuvent créer un lien vers notre site web sans approbation écrite préalable :</p>

                <ul>
                    <li>Les agences gouvernementales ;</li>
                    <li>Les moteurs de recherche ;</li>
                    <li>Les organisations de presse ;</li>
                    <li>Les distributeurs d'annuaires en ligne peuvent créer un lien vers notre site web de la même manière qu'ils créent des liens vers les sites web d'autres entreprises répertoriées ; et</li>
                    <li>Les entreprises accréditées à l'échelle du système, sauf les organisations à but non lucratif sollicitant, les centres commerciaux caritatifs, et les groupes de collecte de fonds caritatifs qui ne peuvent pas créer de lien vers notre site web.</li>
                </ul>

                <p>Ces organisations peuvent créer un lien vers notre page d'accueil, vers des publications ou vers d'autres informations du site web tant que le lien : (a) n'est pas de manière trompeuse ; (b) n'implique pas faussement un parrainage, une approbation ou une approbation de la partie créant le lien et de ses produits et/ou services ; et (c) s'intègre dans le contexte du site de la partie créant le lien.</p>

                <p>Nous pouvons envisager et approuver d'autres demandes de lien des types d'organisations suivants :</p>

                <ul>
                    <li>des sources d'informations pour les consommateurs et/ou les entreprises communément connues ;</li>
                    <li>des sites communautaires dot.com ;</li>
                    <li>des associations ou autres groupes représentant des œuvres caritatives ;</li>
                    <li>des distributeurs d'annuaires en ligne ;</li>
                    <li>des portails internet ;</li>
                    <li>des cabinets comptables, juridiques et de conseil ; et</li>
                    <li>des institutions éducatives et des associations professionnelles.</li>
                </ul>

                <p>Nous approuverons les demandes de lien de ces organisations si nous décidons que : (a) le lien ne nous fera pas paraître défavorablement à nous-mêmes ou à nos entreprises accréditées ; (b) l'organisation n'a aucun dossier négatif avec nous ; (c) le bénéfice pour nous de la visibilité de l'hyperlien compense l'absence de SIMDEV ; et (d) le lien est dans le contexte d'informations de ressource générale.</p>

                <p>Ces organisations peuvent créer un lien vers notre page d'accueil tant que le lien : (a) n'est pas de manière trompeuse ; (b) n'implique pas faussement un parrainage, une approbation ou une approbation de la partie créant le lien et de ses produits ou services ; et (c) s'intègre dans le contexte du site de la partie créant le lien.</p>

                <p>Nous pouvons envisager et approuver d'autres demandes de lien de la part des types d'organisations suivants :</p>

                <ul>
                    <li>sources d'informations pour les consommateurs et/ou les entreprises communément connues ;</li>
                    <li>sites communautaires dot.com ;</li>
                    <li>associations ou autres groupes représentant des œuvres caritatives ;</li>
                    <li>distributeurs d'annuaires en ligne ;</li>
                    <li>portails internet ;</li>
                    <li>cabinets comptables, juridiques et de conseil ; et</li>
                    <li>institutions éducatives et associations professionnelles.</li>
                </ul>

                <p>Nous approuverons les demandes de lien de ces organisations si nous décidons que : (a) le lien ne nous fera pas paraître défavorablement à nous-mêmes ou à nos entreprises accréditées ; (b) l'organisation n'a aucun dossier négatif avec nous ; (c) le bénéfice pour nous de la visibilité de l'hyperlien compense l'absence de SIMDEV ; et (d) le lien est dans le contexte d'informations de ressource générale.</p>

                <p>Ces organisations peuvent créer un lien vers notre page d'accueil tant que le lien : (a) n'est pas de manière trompeuse ; (b) n'implique pas faussement un parrainage, une approbation ou une approbation de la partie créant le lien et de ses produits ou services ; et (c) s'intègre dans le contexte du site de la partie créant le lien.</p>

                <p>Si vous êtes l'une des organisations listées dans le paragraphe 2 ci-dessus et êtes intéressé à créer un lien vers notre site web, vous devez nous en informer en envoyant un e-mail à SIMDEV. Veuillez inclure votre nom, le nom de votre organisation, vos coordonnées ainsi que l'URL de votre site, une liste de toutes les URL depuis lesquelles vous avez l'intention de créer un lien vers notre site Web, et une liste des URL sur notre site vers lesquelles vous souhaitez créer un lien. Attendez 2-3 semaines pour une réponse.</p>

                <p>Les organisations approuvées peuvent créer un hyperlien vers notre site Web comme suit :</p>

                <ul>
                    <li>En utilisant notre nom d'entreprise ; ou</li>
                    <li>En utilisant l'URL uniforme liée ; ou</li>
                    <li>En utilisant toute autre description de notre site Web lié qui a du sens dans le contexte et le format du contenu sur le site de la partie créant le lien.</li>
                </ul>

                <p>Aucune utilisation du logo de SIMDEV ou d'autres œuvres d'art ne sera autorisée pour la création de lien en l'absence d'un accord de licence de marque.</p>

                <h3><strong>iFrames</strong></h3>

                <p>Sans approbation préalable et autorisation écrite, vous ne pouvez pas créer de cadres autour de nos pages Web qui altèrent de quelque manière que ce soit la présentation visuelle ou l'apparence de notre site Web.</p>

                <h3><strong>Responsabilité du contenu</strong></h3>

                <p>Nous ne serons pas tenus responsables de tout contenu qui apparaît sur votre site Web. Vous acceptez de nous protéger et de nous défendre contre toutes les réclamations qui surgissent sur votre site Web. Aucun lien ne doit apparaître sur un site Web qui peut être interprété comme diffamatoire, obscène ou criminel, ou qui porte atteinte, viole autrement, ou prône la violation ou autre violation des droits de tiers.</p>

                <h3><strong>Réserve de droits</strong></h3>

                <p>Nous nous réservons le droit de demander que vous supprimiez tous les liens ou un lien particulier vers notre site Web. Vous acceptez de supprimer immédiatement tous les liens vers notre site Web sur demande. Nous nous réservons également le droit de modifier ces termes et conditions et sa politique de lien à tout moment. En continuant à créer des liens vers notre site Web, vous acceptez d'être lié et de suivre ces termes et conditions de lien.</p>

                <h3><strong>Suppression de liens de notre site web</strong></h3>

                <p>Si vous trouvez un lien sur notre site Web qui est offensant pour une raison quelconque, vous êtes libre de nous contacter et de nous en informer à tout moment. Nous envisagerons les demandes de suppression de liens mais nous ne sommes pas obligés de le faire ou de vous répondre directement.</p>

                <p>Nous ne garantissons pas que les informations sur ce site Web sont correctes, nous ne garantissons pas leur complétude ou leur exactitude ; ni ne promettons de garantir que le site Web reste disponible ou que le matériel sur le site Web est maintenu à jour.</p>

                <h3><strong>Avertissement</strong></h3>

                <p>Dans la mesure maximale permise par la loi applicable, nous excluons toutes représentations, garanties et conditions relatives à notre site Web et à l'utilisation de ce site Web. Rien dans cet avertissement ne va :</p>

                <ul>
                    <li>limiter ou exclure notre ou votre responsabilité pour décès ou blessure corporelle ;</li>
                    <li>limiter ou exclure notre ou votre responsabilité pour fraude ou fausse déclaration frauduleuse ;</li>
                    <li>limiter l'une de nos ou vos responsabilités de quelque manière que ce soit qui n'est pas autorisée en vertu de la loi applicable ; ou</li>
                    <li>exclure l'une de nos ou vos responsabilités qui ne peuvent pas être exclues en vertu de la loi applicable.</li>
                </ul>

                <p>Les limitations et interdictions de responsabilité énoncées dans cette section et ailleurs dans cet avertissement : (a) sont soumises au paragraphe précédent ; et (b) régissent toutes les responsabilités découlant de l'avertissement, y compris les responsabilités découlant du contrat, de la responsabilité délictuelle et pour violation de l'obligation statutaire.</p>

                <p>Tant que le site Web et les informations et services sur le site Web sont fournis gratuitement, nous ne serons pas responsables de toute perte ou dommage de quelque nature que ce soit.</p>
            </div>
        </div>
    )
}