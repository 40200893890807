import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavBar } from './Components/Navbar/Navbar';
import Management from './Pages/ManagementPage/ManagementPage';
import Construction from './Pages/ConstructionPage/ConstructionPage';
import Projects from './Pages/ProjectsPage/ProjectsPage';
import About from "./Pages/AboutPage/AboutPage";
import Home from './Pages/HomePage/HomePage';
import ContactForm from './Pages/ContactPage/ContactPage';
import Privacy from "./Pages/PrivacyPage/PrivacyPage";
import './Styles/App.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Components/Footer/Footer';
import { ParallaxProvider } from "react-scroll-parallax";
import Careers from "./Pages/CareersPage/CareersPage";
import Terms from "./Pages/TermsPage/terms";
import { ScrollToTop } from "./Components/Functions/ScrollToTop";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
import SplashScreen from "./Components/Animations/SplashScreen/SplashScreen";
function App() {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('bootstrap/dist/css/bootstrap.min.css');
    }
  }, []);

  /*
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate video loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Set the duration of your splash screen

    return () => clearTimeout(timer); // Clean up timer on unmount
  }, []);
  
  
  if (isLoading) {
    return <SplashScreen />;
  }
  
  else{
    */
  
    return (
      <ParallaxProvider>
        <ScrollToTop/>
        <NavBar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/construction' element={<Construction/>}/>
          <Route path='/gestion' element={<Management/>}/>
          <Route path='/projets' element={<Projects/>}/>
          <Route path='/histoire' element={<About/>}/>
          <Route path='/nousrejoindre' element={<ContactForm/>}/>
          <Route path='/emplois' element={<Careers/>}/>
          <Route path='/nouvelles' element={<ContactForm/>}/>
          <Route path='/accolades' element={<ContactForm/>}/>
          <Route path='/avis-legal' element={<Terms/>}/>
          <Route path='/politique-confidentialite' element={<Privacy/>}/>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer/>
      </ParallaxProvider>
    );
  }
  /*
}
*/


export default App;

