import React from 'react'
import "../../i18n";
import { useTranslation } from "react-i18next";
import CardList from '../../Components/Cards/CardList';
import image from '../../Assets/images/Pages/project-image.jpg'
import SingleLayerIntroSection from '../../Components/Text/ParallaxTitle/Simple/SimpleParallaxTitle';


export default function Home(){
    const { t } = useTranslation();

    return (
        <div>
            <div>
                <div>
                    <SingleLayerIntroSection title={t("projects.title")} backgroundImage={image}/>
                </div>
                <div>
                    <CardList/>
                </div>
            </div>
        </div>
    )
}

