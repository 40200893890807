import slidesLVSH from './slides_LVSH';
import { featuresLVSH } from './features_LVSH';
import { ProjectType } from '../../Constants';
import { textLVSH } from './text_LVSH';

export const LVSHdata = {
    LVSHdata_fr: {
        id: 6,
        title: 'LeVIVO Sherbrooke',
        description: textLVSH.fr,
        yearBuilt: 2021,
        location: "Sherbrooke",
        website: "https://levivo.ca/en/rentals-condos/levivo-sherbrooke/",
        category: ProjectType.RealEstate,
        images: slidesLVSH,
        features: featuresLVSH.fr

    },
    LVSHdata_en: {
        id: 6,
        title: 'LeVIVO Sherbrooke',
        description: textLVSH.en,
        yearBuilt: 2021,
        location: "Sherbrooke",
        website: "https://levivo.ca/en/rentals-condos/levivo-sherbrooke/",
        category: ProjectType.RealEstate,
        images: slidesLVSH,
        features: featuresLVSH.en
    }
}