export const HomeData = {
    home_en: {
        title: {
            "title": "SIMDEV",
            "subtitle": "We are"
        },
        description: {
            "title": "The art of building with passion",
            "firstText": "SIMDEV specializes in the design and realization of large real estate projects that transform the urban landscape of the province. We envision and create innovative and integrated living environments, offering residents an exceptional quality of life. By combining innovation and creativity, our company contributes to the flourishing of the communities we develop.",
            "secondText": "What particularly distinguishes SIMDEV is our commitment to participate in all stages of urban development. Indeed, the company ensures both the design, construction, leasing, and management of its real estate projects. Notably recognized for its expertise in residential development, SIMDEV's initiatives seamlessly blend spaces for living, working, and entertainment.",
        },
        stats: {
            title: {
                "title": "SIMDEV. In numbers."
            },
            circles: {
                first: {
                    "side-text": "",
                    "text": "units"
                },
                second: {
                    "side-text": "pi2",
                    "text": "built"
                },
                third: {
                    "side-text": "pi2",
                    "text": "managed"
                },
                fourth: {
                    "side-text": "years",
                    "text": "Of innovation"
                }
            }
        },
        button: {
            "text": "See our projects"
        }
    },
    home_fr: {
        title: {
            "title": "SIMDEV",
            "subtitle": "Nous sommes"
        },
        description: {
            "title": "L'art de construire avec passion",
            "firstText": "SIMDEV se spécialise dans la conception et la réalisation de grands projets immobiliers qui transforment le visage urbain de la province. Nous imaginons et réalisons des environnements de vie novateurs et intégrés, offrant aux résidents une qualité de vie exceptionnelle. En alliant innovation et créativité, notre entreprise contribue à l'épanouissement des communautés que nous développons.",
            "secondText": "Ce qui distingue particulièrement SIMDEV est notre engagement à participer à tous les stades du développement urbain. En effet, l'entreprise assure à la fois la conception, la construction, la location et la gestion de ses projets immobiliers. Notamment reconnue pour sa compétence en matière de développement résidentiel, les initiatives de SIMDEV fusionnent harmonieusement des espaces pour vivre, travailler et se divertir.",
        },
        stats: {
            title: {
                "title": "SIMDEV EN NOMBRES."
            },
            circles: {
                first: {
                    "side-text": "",
                    "text": "unités"
                },
                second: {
                    "side-text": "pi2",
                    "text": "construit"
                },
                third: {
                    "side-text": "pi2",
                    "text": "gérés"
                },
                fourth: {
                    "side-text": "ans",
                    "text": "d'innovation"
                }
            }
        },
        button: {
            "text": "Voir nos projets"
        }
    },

  };
  