import slidesLVLO2 from './slides_LVLO2';
import { featuresLVLO2 } from './features_LVLO2';
import { ProjectType } from '../../Constants';
import { textLVLO2 } from './text_LVLO2';

export const LVLO2data = {
    LVLO2data_fr:{
        id: 5,
        title: 'LeVIVO Longueuil 2',
        description: textLVLO2.fr,
        yearBuilt: 2022,
        location: "Longueuil",
        website: "https://levivo.ca/en/rentals-condos/levivo-longueuil-2/",
        category: ProjectType.RealEstate,
        images: slidesLVLO2,
        features: featuresLVLO2.fr
    },
    LVLO2data_en: {
        id: 5,
        title: 'LeVIVO Longueuil 2',
        description: textLVLO2.en,
        yearBuilt: 2022,
        location: "Longueuil",
        website: "https://levivo.ca/en/rentals-condos/levivo-longueuil-2/",
        category: ProjectType.RealEstate,
        images: slidesLVLO2,
        features: featuresLVLO2.en
    }
    
}