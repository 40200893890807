// NotFoundPage.tsx
import React from 'react';
import './NotFoundPage.scss';
import MyButton from '../../Components/Buttons/SimpleButton/Button';
import { useNavigate } from 'react-router-dom';




export function NotFoundPage() {
  
  const navigate = useNavigate();
  const handleClickfunc = async () => {
    await /* API call here */
    navigate('/');
  }

  return (
    <div>
      <div className="notfound-container ">
        <h1>404</h1>
        <p>Oops! The page you are looking for does not exist.</p>
        <MyButton OnClick={handleClickfunc} text='Return home'/>
      </div>
    </div>
  );
}

export default NotFoundPage;