export const NavbarData = {
    navbar_en: {
        "construction-link": "Construction",
        "management-link": "Management",
        "projects-link": "Projects",
        "history-link": "History",
        "careers-link": "Careers",
        "contact-button": "Contact Us",
    },
    navbar_fr: {
        "construction-link": "Construction",
        "management-link": "Gestion",
        "projects-link": "Projets",
        "history-link": "Histoire",
        "careers-link": "Carrières",
        "contact-button": "Contactez-nous"
    }
  };
  