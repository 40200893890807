export const CareersPageText = {
    fr: [
        "Chez SIMDEV nous ne nous contentons pas de tracer des chemins ; nous construisons des horizons. Nous sommes une équipe passionnée et dévouée, animée par la conviction que l'excellence naît de la collaboration, de l'innovation et du bien-être de nos employés.",
        "Imaginez-vous dans un environnement de travail où chaque jour est une opportunité de repousser les limites, où la créativité est encouragée et où vos idées contribuent à façonner le futur.",
        "Notre siège social, métamorphosé en un espace moderne et convivial, reflète notre engagement envers le bien-être de nos employés.Des bureaux conçus pour inspirer, des espaces de travail collaboratifs, une cuisine bien équipée pour des pauses conviviales, un lounge accueillant pour des échanges informels : tout a été pensé pour favoriser une ambiance chaleureuse et stimulante.",
        "Notre équipe, jeune, dynamique et motivée, incarne l'esprit de notre entreprise. Nous croyons en la diversité des talents, en la force de la collaboration et en la liberté d'expression. Chaque voix compte et chaque idée est valorisée.",
        "Chez nous, l'ambition professionnelle est synonyme d'opportunités. Nous encourageons la croissance individuelle, offrant des programmes de formation et de développement pour nourrir vos compétences et cultiver votre potentiel.",
        "Mais au-delà du travail, nous construisons une communauté. Des événements d'entreprise, des activités sociales et une atmosphère conviviale créent des liens qui vont au-delà des horaires de bureau.",
        "Si vous recherchez plus qu'un emploi, mais une véritable aventure professionnelle où votre passion, votre créativité et votre engagement sont valorisés, alors SIMDEV est l'endroit où vous pouvez réaliser vos aspirations.",
        "Rejoignez-nous pour façonner ensemble un avenir où l'innovation, la collaboration et l'épanouissement professionnel sont au cœur de chaque journée de travail.",
    ],
    en: [
        "At SIMDEV, we do more than just forge paths; we build horizons. We are a passionate and dedicated team, driven by the belief that excellence stems from collaboration, innovation, and the well-being of our employees.",
        "Imagine yourself in a work environment where every day is an opportunity to push the boundaries, where creativity is encouraged, and where your ideas contribute to shaping the future.",
        "Our headquarters, transformed into a modern and welcoming space, reflects our commitment to the well-being of our employees. Offices designed to inspire, collaborative workspaces, a well-equipped kitchen for friendly breaks, a welcoming lounge for informal exchanges: everything has been thought out to foster a warm and stimulating atmosphere.",
        "Our team, young, dynamic, and motivated, embodies the spirit of our company. We believe in the diversity of talents, the strength of collaboration, and the freedom of expression. Every voice counts, and every idea is valued.",
        "With us, professional ambition is synonymous with opportunities. We encourage individual growth, offering training and development programs to nurture your skills and cultivate your potential.",
        "But beyond work, we build a community. Corporate events, social activities, and a friendly atmosphere create bonds that extend beyond office hours.",
        "If you are looking for more than just a job, but a true professional adventure where your passion, creativity, and commitment are valued, then SIMDEV is the place where you can realize your aspirations.",
        "Join us to shape together a future where innovation, collaboration, and professional fulfillment are at the heart of every working day."
    ]
} 

export const CareersPageData = {
    CareersPageData_en: {
        title: {
            "text": "Join the SIMDEV family"
        },
        description: {
            "title": "Life at SIMDEV",
            text: CareersPageText.en
        }
    },
    CareersPageData_fr:{
        title: {
            "text": "Rejoignez la famille SIMDEV"
        },
        description: {
            "title": "La vie chez SIMDEV",
            text: CareersPageText.fr
        }
    }
}
