import image1 from "../../../Assets/images/Local 144/pictures/LOC-01A.jpg";
import image2 from "../../../Assets/images/Local 144/pictures/LOC-01B.jpg";
import image4 from "../../../Assets/images/Local 144/pictures/LOC-01C.jpg";
import image5 from "../../../Assets/images/Local 144/pictures/LOC-01E.jpg";
import image6 from "../../../Assets/images/Local 144/pictures/LOC-01G.jpg";
import image7 from "../../../Assets/images/Local 144/pictures/LOC-01H.jpg";
import image8 from "../../../Assets/images/Local 144/pictures/LOC-01J.jpg";
import image9 from "../../../Assets/images/Local 144/pictures/LOC-02.jpg";
import image10 from "../../../Assets/images/Local 144/pictures/LOC-03.jpg";
import image11 from "../../../Assets/images/Local 144/pictures/LOC-04.jpg";
import image12 from "../../../Assets/images/Local 144/pictures/LOC-05.jpg";
import image13 from "../../../Assets/images/Local 144/pictures/LOC-06.jpg";
import image14 from "../../../Assets/images/Local 144/pictures/LOC-07.jpg";
import image15 from "../../../Assets/images/Local 144/pictures/LOC-08.jpg";
import image16 from "../../../Assets/images/Local 144/pictures/LOC-10.jpg";
import image17 from "../../../Assets/images/Local 144/pictures/LOC-11.jpg";
import image18 from "../../../Assets/images/Local 144/pictures/LOC-12.jpg";


/* IMAGES LEVIVO LAPRAIRIE*/

export const slidesL144 = [
    {
        url: image1
    },
    {
        url: image2
    },
    {
        url: image4
    },
    {
        url: image5
    },
    {
        url: image6
    },
    {
        url: image7
    },
    {
        url: image8
    },
    {
        url: image9
    },
    {
        url: image10
    },
    {
        url: image11
    },
    {
        url: image12
    },
    {
        url: image13
    },
    {
        url: image14
    },
    {
        url: image15
    },
    {
        url: image16
    },
    {
        url: image17
    },
    {
        url: image18
    },
    
]

export default slidesL144;